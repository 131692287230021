@import "./common.scss";

.card {
  padding: $spacing-normal;
  border-radius: $border-radius;
  background-color: $color-card-bg;
  cursor: pointer;
  transition: box-shadow $animation-speed-slow;
}

.card-template {
  display: none;
}

img.icon {
  transition: opacity $animation-speed-fast;
  height: 40px;
  filter: invert(1);
  opacity: 0.6;
}

.card:hover {
  box-shadow: $shadow-big;
  img.icon {
    opacity: 0.9;
  }
}

section.landing {
  margin-top: 25vh;

  h1 {
    margin: $spacing-x-small 0;
  }

  h2 {
    line-height: 100%;
  }

  .logo {
    margin-top: 24px;
    margin-bottom: 72px;
    display: flex;
    .line {
      height: 2px;
      width: 100%;
      background-color: $color-text-primary;
      border-radius: 1px;
    }

    .square {
      width: 48px;
      height: 48px;
      background-color: #fff100;
      margin-top: -16px;
      margin-left: -48px;
      border-radius: $border-radius;
    }

    .square1 {
      transform: translateX(-104px);
    }

    .square2 {
      transform: translateX(-48px);
    }
  }

  @media screen and (min-width: 800px) {
    .logo {
      margin-top: 3vw;
      margin-bottom: 9vw;

      .square {
        width: 6vw;
        height: 6vw;
        margin-top: -2vw;
        margin-left: -6vw;
      }

      .square1 {
        transform: translateX(-13vw);
      }

      .square2 {
        transform: translateX(-6vw);
      }
    }
  }

  @media screen and (min-width: 1600px) {
    .logo {
      margin-top: 48px;
      margin-bottom: 144px;

      .square {
        width: 96px;
        height: 96px;
        margin-top: -32px;
        margin-left: -96px;
      }

      .square1 {
        transform: translateX(-208px);
      }

      .square2 {
        transform: translateX(-96px);
      }
    }
  }
}

section.calculator {
  margin-top: 10vh;

  .card {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    grid-gap: $spacing-small;
    align-items: center;

    p {
      grid-column: span 2;
    }
  }

  @media screen and (min-width: 600px) {
    .card {
      grid-gap: $spacing-x-small $spacing-normal;

      img.icon {
        grid-row: span 2;
      }

      p {
        grid-column: span 1;
      }
    }
  }
}

section.was-wir-bieten {
  margin-top: 10vh;

  h2 {
    margin-bottom: 6vh;
  }

  .grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-auto-flow: row;
    grid-gap: $spacing-small;

    .card {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-auto-rows: auto;
      align-items: center;
      grid-gap: $spacing-small;

      p {
        grid-column: span 2;
      }
    }

    .pruefung,
    .messtechnik {
      align-self: end;
    }

    .arbeitssicherheit,
    .kontrollen {
      align-self: start;
    }
  }

  @media screen and (min-width: 450px) {
    .grid {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: auto;

      .card--long {
        grid-column: span 2;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .grid {
      grid-gap: $spacing-normal;

      .card {
        grid-gap: $spacing-normal;
      }
    }
  }

  @media screen and (min-width: 630px) {
    .grid {
      .card--long {
        grid-column: span 1;
      }
    }
  }
}

@media screen and (min-width: 400px) {
  img.icon {
    height: 10vw;
  }
}

@media screen and (min-width: 600px) {
  img.icon {
    height: 60px;
  }
}

section.das-sind-wir {
  margin-top: 10vh;

  h2 {
    margin-bottom: 6vh;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-gap: $spacing-small;

    .card {
      max-width: 100%;
      cursor: auto;
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      grid-auto-flow: row;
      grid-gap: $spacing-x-small;

      img {
        max-width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: $border-radius;
      }

      a {
        color: $color-text-secondary;
        transition: color $animation-speed-fast;

        &:hover {
          color: $color-text-primary;
        }
      }
    }

    @media screen and (min-width: 450px) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 600px) {
      grid-gap: $spacing-normal;
    }
  }
}
